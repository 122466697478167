import { motion, useTransform, useViewportScroll } from "framer-motion"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import Image from "../components/image"
import Layout from "../components/Layouts/layout"
import GoogleMap from "../components/Map"
import SEO from "../components/seo"

const PageContact = props => {
  // console.log(props.data.wpPage.seo)

  let {
    location,
    data: {
      wpPage: {
        title,
        featuredImage,
        seo,
        acf_contact: { dubaiAddress: dubai, germanyAddress: germany },
      },
    },
  } = props

  const { scrollYProgress } = useViewportScroll()

  /*  const up = useTransform(
    scrollYProgress,
    [0, 0.1, 0.2, 0.3, 0.4, 0.5],
    ["0%", "20%", "40%", "60%", "80%", "100%"]
  ) */

  const down = useTransform(
    scrollYProgress,
    [0, 0.5, 1],
    ["0%", "-15%", "-30%"]
  )

  return (
    <Layout location={location} title={title}>
      <SEO bodyClasses="contact" seo={seo} />
      <div className="container-fluid p-0">
        <div className="row no-gutters">
          <div className="col-12 headerimage">
            <motion.div style={{ top: 0, y: down }}>
              <Image data={featuredImage} />
            </motion.div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row d-none">
          <div className="col-md-12">
            <h1 className="heading two">{title}</h1>
          </div>
        </div>
        <div className="row article">
          <div className="col-md-6 mb-5">{parse(dubai)}</div>
          <div className="col-md-6">{parse(germany)}</div>
        </div>
      </div>

      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-md-12 map">
            {/* <Location /> */}
            <GoogleMap />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PageContact

export const pageContact = graphql`
  {
    wpPage(databaseId: { eq: 26 }) {
      id
      title
      content
      featuredImage {
        node {
          ...fragmentImage
        }
      }
      acf_contact {
        dubaiAddress
        germanyAddress
      }
      seo {
        ...WpYoastSEO
      }
    }
  }
`
