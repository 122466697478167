import parse from "html-react-parser"
import React from "react"
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps"
import { compose, withProps, withStateHandlers } from "recompose"

const FancyMapStyles = require("./FancyMapStyles.json")

const MapWithPlaces = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDq-TbJta8RSlG9JZnJ6Vb0ZGAJ2AFCKT8",
    loadingElement: <div className="maploading" />,
    containerElement: <div className="mapconatiner" />,
    mapElement: <div className="mapelement" />,
  }),
  withStateHandlers(
    props => ({
      infoWindows: props.places.map(p => {
        return { isOpen: false }
      }),
    }),
    {
      onToggleOpen: ({ infoWindows }) => selectedIndex => ({
        infoWindows: infoWindows.map((iw, i) => {
          iw.isOpen = selectedIndex === i
          return iw
        }),
      }),
    }
  ),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultOptions={{
      styles: FancyMapStyles,
      mapTypeControl: false,
      scrollwheel: false,
    }}
    defaultZoom={props.zoom}
    defaultCenter={props.center}
  >
    {props.places &&
      props.places.map((place, i) => {
        let lat = parseFloat(place.latitude, 10)
        let lng = parseFloat(place.longitude, 10)

        return (
          <Marker
            id={place.id}
            key={place.id}
            position={{ lat: lat, lng: lng }}
            title="Click to zoom"
            onClick={props.onToggleOpen.bind(this, i)}
          >
            {props.infoWindows[i].isOpen && (
              <InfoWindow onCloseClick={props.onToggleOpen.bind(i)}>
                <div>
                  <p className="mt-3">
                    <strong>{parse(place.name)}</strong>
                  </p>
                  <p className="mb-3">
                    <a
                      href={place.link}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      Get directions
                    </a>
                  </p>
                </div>
              </InfoWindow>
            )}
          </Marker>
        )
      })}
  </GoogleMap>
))

export default MapWithPlaces
