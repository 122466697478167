import React from "react"
import Map from "./Map"
import places from "./places.json"

function GoogleMap() {
  var isMobile = false
  if (typeof window !== `undefined`) {
    isMobile = window.matchMedia("(max-width: 768px)").matches
  }
  return (
    <>
      <Map
        center={{ lat: 38.94815, lng: 32.44015 }}
        zoom={isMobile ? 3 : 4}
        places={places}
      />
    </>
  )
}

export default GoogleMap
